export default {
  async rowArticulo (Vue, idarticulo) {
    const tables = Vue.$offline.db.tables
    return (await Vue.$offline.db
      .select()
      .from(tables.articulo)
      .innerJoin(
        tables.subfamilia,
        tables.articulo.idsubfamilia.eq(tables.subfamilia.idsubfamilia)
      )
      .where(
        Vue.$offline.db.op.and(
          tables.articulo.idarticulo.eq(idarticulo)
        )
      )
      .exec()
    )[0]
  },
  async rowParteTrabajoMatsist (Vue, idparteTrabajoMatsist) {
    const tables = Vue.$offline.db.tables
    const parteTrabajoMatsist = await Vue.$offline.db
      .select()
      .from(tables.parte_trabajo_matsist)
      .innerJoin(
        tables.parte_trabajo,
        tables.parte_trabajo_matsist.idparte_trabajo.eq(tables.parte_trabajo.idparte_trabajo)
      )
      .innerJoin(
        tables.orden_trabajo,
        tables.parte_trabajo.idorden_trabajo.eq(tables.orden_trabajo.idorden_trabajo)
      )
      .innerJoin(
        tables.sistema,
        tables.orden_trabajo.idsistema.eq(tables.sistema.idsistema)
      )
      .innerJoin(
        tables.cliente,
        tables.sistema.idcliente.eq(tables.cliente.idcliente)
      )
      .innerJoin(
        tables.orden_trabajo_matsist,
        tables.parte_trabajo_matsist.idorden_trabajo_matsist.eq(tables.orden_trabajo_matsist.idorden_trabajo_matsist)
      )
      .innerJoin(
        tables.material_sistema,
        tables.orden_trabajo_matsist.idmaterial_sistema.eq(tables.material_sistema.idmaterial_sistema)
      )
      .innerJoin(
        tables.subsis,
        tables.material_sistema.idsubsis.eq(tables.subsis.idsubsis)
      )
      .innerJoin(
        tables.tsubsis,
        tables.subsis.idtsubsis.eq(tables.tsubsis.idtsubsis)
      )
      .innerJoin(
        tables.articulo,
        tables.material_sistema.idarticulo.eq(tables.articulo.idarticulo)
      )
      .where(
        Vue.$offline.db.op.and(
          tables.parte_trabajo_matsist.idparte_trabajo_matsist.eq(idparteTrabajoMatsist)
        )
      )
      .exec()
    return parteTrabajoMatsist[0]
  },
  async insert (Vue, formData, idparteTrabajoMatsist, idaccionEjec, idtecnico, idparteTrabajo, idarticulo) {
    const tables = Vue.$offline.db.tables
    const accionEjec = (await Vue.$offline.db
      .select()
      .from(tables.accion_ejec)
      .innerJoin(
        tables.maccion,
        tables.accion_ejec.idmaccion.eq(tables.maccion.idmaccion)
      )
      .where(
        Vue.$offline.db.op.and(
          tables.accion_ejec.idaccion_ejec.eq(idaccionEjec)
        )
      )
      .exec()
    )[0]
    // en una sustitución siempre se agrega LPARTE_TRABAJO
    // facturable y no facturable
    const lparteTrabajo = await Vue.$offline.accionEjec.marcarAccionEjecRealizadaSync(
      idaccionEjec,
      null,
      accionEjec.accion_ejec.facturable,
      null,
      formData.tinclusion,
      idtecnico,
      idparteTrabajo,
      idarticulo
    )
    return lparteTrabajo
  },
}
